'use client';

import { useMutation, useQuery } from '@apollo/client';
import { useCheckout } from '@app/_pages/checkout-v2/Checkout.hooks';
import { useMarket } from '@context/marketContext';
import type {
    CartItem,
    CartItemInput,
    CartMetadataInput,
    CreateCartMutation,
    GetCartQuery,
} from '@server/gql/graphql';
import {
    addGiftCardMutation,
    addPromotionMutation,
    addToCartMutation,
    clearCartMutation,
    clearPromotionMutation,
    createCartMutation,
    getCartQuery,
    removeFromCartMutation,
    removeGiftCardMutation,
    updateCartMetadataMutation,
    updateCartMutation,
} from '@server/queries/cart';
import { setDeliveryOptionMutation } from '@server/queries/checkout';
import { getCheckoutDelivery } from '@server/requests/checkout';
import getSearchEngineUserUid from '@server/utils/getSearchEngineUserUid';
import { setCookie } from 'cookies-next';
import { useMemo, useRef, useState } from 'react';
import type { Variant } from 'types';

import { CART_COOKIE } from '@/config/cart';
import { getCartCookie } from '@/utils/cart/getCartCookie';
import { COOKIE_MAX_AGE } from '@/utils/const';
import createLoopEvent from '@/utils/createLoopEvent';
import { getGoogleTokenForVariant } from '@/utils/googlePricing';
import { gTagAddToCart, gTagRemoveFromCart } from '@/utils/googleTags';
import getOptimisticResponse from '@/utils/optimistic/getOptimisticResponse';
import {
    sendVoyadoCartChangeEvent,
    sendVoyadoEmptyCartEvent,
} from '@/utils/voyadoTracking';

import type { AddItemPayload } from '../../types/cart';

export type SetDeliveryOptionPayload = {
    zipCode: string;
    deliveryOptionId: string;
    deliveryAgent: string;
    priceValue: number;
    deliveryOptionName: string;
};

function getVatRateFromItem(item: CartItem) {
    const { vatPerItem = 1, price = 1 } = item;
    return 1 + vatPerItem / (price - vatPerItem);
}

const prepareLoopCartItem = (props: {
    item: Partial<Variant>;
    quantity: number;
}): CartItemInput => {
    const { item, quantity } = props;

    if (!item.id) {
        throw new Error('Error in prepareLoopCartItem: invalid item id');
    }

    const googlePv2Token = getGoogleTokenForVariant(item.id);

    return {
        quantity,
        partNo: item.id,
        vatRate: item.vatRate,
        googlePv2Token,
    };
};

export default function useCartQuery() {
    const market = useMarket().state.market;
    const { contentfulLocale: contentfulLanguage, serviceLocale: locale } =
        market;
    const cartCookie = getCartCookie();
    const searchEngineUser = getSearchEngineUserUid();
    const { refetch: refetchCheckout } = useCheckout();

    const {
        data,
        error: errorGetCart,
        client,
        loading: loadingGetCart,
        refetch,
        called,
    } = useQuery(getCartQuery, {
        variables: {
            cartId: cartCookie?.value ?? '',
            locale,
        },
        skip: !cartCookie?.value,
    });

    const debounce = useRef<ReturnType<typeof setTimeout>>();

    const [optimisticallyCreatedCart, setOptimisticallyCreatedCart] =
        useState<CreateCartMutation['cart']>();

    const [
        mutateAddToCart,
        { loading: loadingAddToCart, error: errorAddToCart },
    ] = useMutation(addToCartMutation, {
        onCompleted: (response, options) => {
            if (!errorAddToCart) {
                gTagAddToCart(options?.variables?.items, response?.cart);
            }
        },
    });

    const [
        mutateCreateCart,
        { loading: loadingCreateCart, error: errorCreateCart },
    ] = useMutation(createCartMutation, {
        onCompleted: (response, options) => {
            const cookie = `${response.cart.id}:${response.cart.token}`;
            setCookie(CART_COOKIE, cookie, { maxAge: COOKIE_MAX_AGE });
            if (!errorCreateCart) {
                gTagAddToCart(options?.variables?.items, response?.cart);
            }
        },
        onError: (apolloError) => {
            setOptimisticallyCreatedCart(undefined);
            throw apolloError;
        },
    });

    const [
        mutateAddPromotion,
        { loading: loadingAddPromotion, error: errorAddPromotion },
    ] = useMutation(addPromotionMutation);

    const [
        mutateClearPromotion,
        { loading: loadingClearPromotion, error: errorClearPromotion },
    ] = useMutation(clearPromotionMutation);

    const [
        mutateUpdateCart,
        { loading: loadingUpdateCart, error: errorUpdateCart },
    ] = useMutation(updateCartMutation, {
        onCompleted: (response, options) => {
            if (!errorUpdateCart) {
                gTagAddToCart(options?.variables?.items, response?.cart);
            }
        },
        optimisticResponse: ({ cartItem }) => {
            const { partNo, quantity } = cartItem;
            const optimisticItem = {
                item: {
                    id: partNo,
                },
                quantity,
            };
            return getOptimisticResponse(data, [optimisticItem]);
        },
    });

    const [
        mutateRemoveItem,
        { loading: loadingRemoveFromCart, error: errorRemoveFromCart },
    ] = useMutation(removeFromCartMutation);

    const [
        mutateClearCart,
        { loading: loadingClearCart, error: errorClearCart },
    ] = useMutation(clearCartMutation);

    const [
        mutateUpdateCartMetadata,
        { loading: loadingUpdateCartMetadata, error: errorUpdateCartMetadata },
    ] = useMutation(updateCartMetadataMutation);

    const [
        mutateSetDeliveryOption,
        {
            loading: loadingUpdateDeliveryOption,
            error: errorUpdateDeliveryOption,
        },
    ] = useMutation(setDeliveryOptionMutation);

    const addPromotion = async (discountCode: string) => {
        await mutateAddPromotion({
            variables: {
                discountCode,
                cartId: cartCookie?.value ?? '',
                locale,
            },
        });

        refetchCheckout();
    };

    const clearPromotions = async () => {
        await mutateClearPromotion({
            variables: {
                cartId: cartCookie?.value ?? '',
                locale,
            },
        });

        refetchCheckout();
    };

    const removeItem = (payload: CartItem) => {
        if (!data?.cart) {
            return Promise.resolve();
        }

        gTagRemoveFromCart(payload, data.cart);
        clearTimeout(debounce.current);

        client.cache.modify({
            id: client.cache.identify(data.cart),
            fields: {
                items: (_, { readField }) => {
                    if (!data.cart) return [];

                    const items = [...data.cart.items];
                    const index = items.findIndex(
                        (i) =>
                            readField<string>('partNo', i) === payload.partNo,
                    );
                    if (index > -1) items.splice(index, 1);
                    return items;
                },
            },
        });

        return mutateRemoveItem({
            variables: {
                cartId: cartCookie?.value ?? '',
                locale,
                partNo: payload?.partNo,
            },
        }).then((res) => {
            notifyVoyadoOfItemRemoval(data.cart, payload);
            return res;
        });
    };

    const notifyVoyadoOfItemRemoval = (
        cart: GetCartQuery['cart'],
        payload: CartItem,
    ) => {
        const cartItems = cart?.items.filter((item) => item.id !== payload.id);

        if (cartItems && cartItems.length > 0) {
            sendVoyadoCartChangeEvent({
                cartId: cartCookie?.id,
                cartItems,
                contentfulLocale: contentfulLanguage,
            });
        }

        sendVoyadoEmptyCartEvent(cartCookie?.id);
    };

    const updateCartMetadata = (metadata: CartMetadataInput) =>
        mutateUpdateCartMetadata({
            variables: {
                cartId: cartCookie?.value ?? '',
                locale,
                metadata,
            },
        });

    const resetCart = () =>
        mutateClearCart({
            variables: {
                cartId: cartCookie?.value ?? '',
                locale,
            },
        }).then((res) => {
            sendVoyadoEmptyCartEvent(cartCookie?.id);
            return res;
        });

    const setDeliveryOption = (payload: SetDeliveryOptionPayload) => {
        return mutateSetDeliveryOption({
            variables: {
                cartId: cartCookie?.value ?? '',
                locale,
                ...payload,
            },
        });
    };

    const notifyVoyadoOfItemUpdate = (cartItems: CartItem[]) => {
        sendVoyadoCartChangeEvent({
            cartId: cartCookie?.value,
            cartItems,
            contentfulLocale: contentfulLanguage,
        });
    };

    const updateItem = async (payload: {
        item: CartItem;
        quantity: number;
    }) => {
        if (payload.quantity <= 0) {
            return mutateRemoveItem({
                variables: {
                    cartId: cartCookie?.value ?? '',
                    locale,
                    partNo: payload?.item?.partNo,
                },
            }).then((data) => {
                const cartModifiedItem = data?.data?.cart.items.find(
                    (item) => item.partNo === payload.item.partNo,
                );
                const cart = data?.data?.cart;

                if (cartModifiedItem && cart) {
                    gTagRemoveFromCart(cartModifiedItem, cart);
                    notifyVoyadoOfItemRemoval(cart, cartModifiedItem);
                }
            });
        }

        const googlePv2Token = getGoogleTokenForVariant(payload.item.partNo);

        return mutateUpdateCart({
            variables: {
                cartId: cartCookie?.value ?? '',
                locale,
                cartItem: {
                    partNo: payload?.item.partNo,
                    quantity: payload?.quantity,
                    vatRate: getVatRateFromItem(payload.item),
                    googlePv2Token,
                },
            },
        }).then((data) => {
            const cartModifiedItems = data?.data?.cart.items;
            const cart = data?.data?.cart;

            if (cartModifiedItems && cart) {
                gTagAddToCart(cartModifiedItems, cart);
                notifyVoyadoOfItemUpdate(cartModifiedItems);
            }
        });
    };

    const addItem = async (payload: AddItemPayload) => {
        const optimisticResponse = getOptimisticResponse(data, payload, true);

        void createLoopEvent({
            marketConfig: market,
            payload: {
                events: payload.map(({ product, item: variant }) => {
                    const id = String(variant.id || product?.id);
                    const type = id === variant.id ? 'Variant' : 'Product';

                    return {
                        type: 'addtocart',
                        entity: { type, id },
                    };
                }),
            },
            searchEngineUser,
        });

        if (!data?.cart) {
            setOptimisticallyCreatedCart(optimisticResponse.cart);

            return mutateCreateCart({
                variables: {
                    items: payload.map(prepareLoopCartItem),
                    locale,
                },
            }).then((res) => {
                if (res.data) {
                    sendVoyadoCartChangeEvent({
                        cartId: String(res.data.cart.id),
                        cartItems: res.data.cart.items,
                        contentfulLocale: contentfulLanguage,
                    });
                }

                return res;
            });
        }

        sendVoyadoCartChangeEvent({
            contentfulLocale: contentfulLanguage,
            cartId: cartCookie?.value,
            cartItems: optimisticResponse.cart.items,
        });

        const inCart = payload.filter((a) =>
            data?.cart?.items.some((b) => b.partNo === a.item.partNo),
        );

        const notInCart = payload.filter((a) =>
            data?.cart?.items.every((b) => b.partNo !== a.item.partNo),
        );

        if (!inCart.length) {
            return mutateAddToCart({
                variables: {
                    locale,
                    cartId: cartCookie?.value ?? '',
                    items: notInCart.map(prepareLoopCartItem),
                },
                optimisticResponse,
            });
        }

        if (!notInCart.length) {
            return Promise.all([
                ...inCart.map((item) =>
                    mutateUpdateCart({
                        variables: {
                            locale,
                            cartId: cartCookie?.value ?? '',
                            cartItem: prepareLoopCartItem(item),
                        },
                    }),
                ),
            ]);
        }

        return Promise.all([
            mutateAddToCart({
                variables: {
                    locale,
                    cartId: cartCookie?.value ?? '',
                    items: notInCart.map(prepareLoopCartItem),
                },
                optimisticResponse,
            }),
            ...inCart.map((item) =>
                mutateUpdateCart({
                    variables: {
                        locale,
                        cartId: cartCookie?.value ?? '',
                        cartItem: prepareLoopCartItem(item),
                    },
                }),
            ),
        ]);
    };

    const getDeliveryCheckout = async (zipCode: string) => {
        return getCheckoutDelivery({
            locale,
            cartId: cartCookie?.value ?? '',
            zipCode,
        });
    };

    const [addGiftCard, { error: errorAddGiftCard }] = useMutation(
        addGiftCardMutation,
        {
            onError: (_addGiftCardError) => {
                console.error(_addGiftCardError);
            },
        },
    );

    const [removeGiftCard] = useMutation(removeGiftCardMutation, {
        onError: (_removeGiftCardError) => {
            console.error(_removeGiftCardError);
        },
    });

    const loading = useMemo(
        () => ({
            getCart: loadingGetCart,
            addToCart: loadingAddToCart,
            createCart: loadingCreateCart,
            addPromotion: loadingAddPromotion,
            clearPromotion: loadingClearPromotion,
            updateCart: loadingUpdateCart,
            removeFromCart: loadingRemoveFromCart,
            clearCart: loadingClearCart,
            updateCartMetadata: loadingUpdateCartMetadata,
            updateDeliveryOption: loadingUpdateDeliveryOption,
        }),
        [
            loadingGetCart,
            loadingAddToCart,
            loadingCreateCart,
            loadingAddPromotion,
            loadingClearPromotion,
            loadingUpdateCart,
            loadingRemoveFromCart,
            loadingClearCart,
            loadingUpdateCartMetadata,
            loadingUpdateDeliveryOption,
        ],
    );

    const error = useMemo(
        () => ({
            getCart: errorGetCart,
            addToCart: errorAddToCart,
            createCart: errorCreateCart,
            addPromotion: errorAddPromotion,
            clearPromotion: errorClearPromotion,
            updateCart: errorUpdateCart,
            removeFromCart: errorRemoveFromCart,
            clearCart: errorClearCart,
            updateCartMetadata: errorUpdateCartMetadata,
            updateDeliveryOption: errorUpdateDeliveryOption,
            giftCard: errorAddGiftCard,
        }),
        [
            errorGetCart,
            errorAddToCart,
            errorCreateCart,
            errorAddPromotion,
            errorClearPromotion,
            errorUpdateCart,
            errorRemoveFromCart,
            errorClearCart,
            errorUpdateCartMetadata,
            errorUpdateDeliveryOption,
            errorAddGiftCard,
        ],
    );

    return {
        cart: data?.cart || optimisticallyCreatedCart,
        empty: !data?.cart?.items.length,
        called,
        error,
        loading,
        isError: Object.values(error).some(Boolean),
        isLoading: Object.values(loading).some(Boolean),
        addItem,
        addPromotion,
        clearPromotions,
        updateItem,
        freezeCheckout: loading,
        removeItem,
        updateCartMetadata,
        resetCart,
        setDeliveryOption,
        getDeliveryCheckout,
        refetchCart: refetch,
        cartId: cartCookie?.id,
        token: cartCookie?.token,
        addGiftCard,
        removeGiftCard,
    };
}
