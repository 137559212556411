import { getCookie } from 'cookies-next';
import { decode } from 'jwt-js-decode';

import { FEATURE_FLAGS } from '@/config/featureFlags';

/**
 * Google Automated Discounts
 * https://support.google.com/merchants/answer/15152429?hl=en#zippy=%2Cstep-of-set-up-conversion-with-cart-data-cwcd%2Cstep-of-complete-url-integration
 */

export type GAD = {
    exp: number;
    o: string;
    m: string;
    p: number;
    c: string;
};

export function getGooglePriceForVariant(variantId?: string) {
    if (!FEATURE_FLAGS.product.googleAutomatedDiscounts) return;
    if (!variantId) return;
    const cookie = getCookie(`pv2:${variantId}`);
    if (typeof cookie !== 'string') return;
    try {
        const payload = decode(cookie).payload as GAD;
        return payload.p;
    } catch {
        return;
    }
}
export function getGoogleTokenForVariant(variantId?: string) {
    if (!FEATURE_FLAGS.product.googleAutomatedDiscounts) return;
    if (!variantId) return;
    const cookie = getCookie(`pv2:${variantId}`);
    if (typeof cookie !== 'string') return;
    return cookie;
}
