import {
    AUTOCOMPLETE_ENDPOINT,
    parseAutocomplete,
} from '@server/parsers/loop54';

import { ONCE_PER_EIGHT_HOURS, REVALIDATION_TAGS } from '@/config/cache';
import type { MarketConfiguration } from '@/config/markets/const';

type Props = {
    query: string;
    marketConfig: MarketConfiguration;
    searchEngineUser: string;
    signal?: AbortController['signal'];
};

const fetchAutocomplete = async (props: Props) => {
    const { query, marketConfig, searchEngineUser, signal } = props;
    if (!query) return null;

    const response = await fetch(
        `${marketConfig.searchEngineUrl}/${AUTOCOMPLETE_ENDPOINT}`,
        {
            method: 'POST',
            headers: {
                'Api-Version': 'V3',
                'User-Id': searchEngineUser,
            },
            body: JSON.stringify({
                query,
                customData: {
                    market: marketConfig.loop54Market,
                    brandResultsOptions: {
                        skip: 0,
                        take: 5,
                    },
                    categoryResultsOptions: {
                        skip: 0,
                        take: 5,
                    },
                    designerResultsOptions: {
                        skip: 0,
                        take: 5,
                    },
                },
                queriesOptions: {
                    take: 50,
                    sortBy: [
                        {
                            type: 'relevance',
                            order: 'asc',
                        },
                    ],
                },
            }),
            next: {
                tags: [REVALIDATION_TAGS.loopAll, REVALIDATION_TAGS.flags],
                revalidate: ONCE_PER_EIGHT_HOURS,
            },
            signal,
        },
    );

    const search = await response.json();

    return parseAutocomplete(search);
};

export default fetchAutocomplete;
