import { useMarket } from '@context/marketContext';
import { fetchAutocomplete } from '@server/requests/product';
import getSearchEngineUserUid from '@server/utils/getSearchEngineUserUid';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

const useAutocomplete = ({ query }: { query: string; enabled?: boolean }) => {
    const { market } = useMarket().state;
    const searchEngineUser = getSearchEngineUserUid();

    const response = useQuery({
        queryKey: ['autocomplete', query],
        queryFn: ({ signal }) =>
            fetchAutocomplete({
                query,
                marketConfig: market,
                searchEngineUser,
                signal,
            }),
        placeholderData: keepPreviousData,
        enabled: !!query,
    });

    return response;
};

export default useAutocomplete;
